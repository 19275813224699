@import 'node_modules/@next-insurance/ni-material/styles/typography-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/mixins';
@import 'node_modules/@next-insurance/ni-material/styles/colors';
@import 'node_modules/@next-insurance/ni-material/styles/typography-variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/next-icon-font';
@import 'node_modules/@next-insurance/ni-material/styles/variables-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/fonts-rebrand';
@import 'node_modules/@next-insurance/ni-material/styles/spacing';
@import 'node_modules/@next-insurance/ni-material/styles/ni-input';
@import 'node_modules/@next-insurance/ni-material/styles/ni-tooltip';
@import 'node_modules/@next-insurance/ni-material/styles/status-label';
@import 'node_modules/@next-insurance/ni-material/styles/date-picker';
@import 'node_modules/@next-insurance/ni-material/styles/ni-dropdown';


$excluded-classes-from-spaces-reset: '.container-bot-message *';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

#{$excluded-classes-from-spaces-reset} {
  margin: revert;
  padding: revert;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button:not(.ni-action-button, .next-frame-close, .p-element) {
  min-width: 200px;
  margin: 0 auto;
  border: 0;
  opacity: 1;
  min-height: 40px;
  padding: 10px;
  cursor: pointer;
}

.ni-action-button {
  border: 2px solid transparent;
  background: none;
  color: var(--ocean-blue);
  cursor: pointer;
}


.button-link.ni-action-button {
  text-decoration: underline;
}

.ni-action-close-button {
  display: flex;
}

.ni-action-button:focus {
  border: 2px solid var(--ocean-blue);
  box-shadow: 0 0 1px 1px var(--ocean-blue);
  border-radius: $border-radius-curvy;
  outline: 0;
}

.auto-min-height button {
  min-height: auto;
}

input {
  text-indent: 10px;
  padding: 5px;

  &::placeholder {
    color: var(--gray-50);
  }

  // hide arrows on number input
  &[type='number'] {
    appearance: textfield; /* Firefox */
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

input[type='text']:focus,
input[type='tel']:focus,
input[type='email']:focus,
textarea:focus {
  outline: 0;
}

a {
  background: transparent;
  color: var(--ocean-blue);
  text-decoration: none;
  transition: color 0.2s;

  &:not([disabled]) {
    cursor: pointer;
  }
}

.cdk-overlay-container {
  z-index: 10000;
}

.ni-fieldset {
  border: none;
}
